// JobNimbus doesn't have an API to get it's user ids.
// This list was manually extracted from the users list UI

export const InsuranceCompanies = [
  'N/A',
  'AAA',
  'ACE - Chubb Limited',
  'Alfa',
  'Allstate',
  'American Family',
  'Amica',
  'Auto Owners',
  'Chubb',
  'Cinicinnati',
  'Country Financial',
  'Farmers',
  'Foremost',
  'GEICO',
  'Georgia Farm Bureau',
  'Georgia Underwriters Association',
  'Grange',
  'Hanover',
  'Hartford',
  'Homesite',
  'Kemper',
  'Lemonade',
  'Liberty Mutual',
  'Mercury',
  'Nationwide',
  'Oakbridge',
  'Openly',
  'Progressive',
  'QBE',
  'Safeco',
  'Safeway',
  'Selective',
  'State Farm',
  'Travelers',
  'USAA',
  'Other'
]

export const RoofHeights = [
  '1 Story',
  '2 Story',
  '3+ Story'
]

export const TimeWindowLabels = {
  '9am-11am': '9 AM - 11 AM',
  '11am-1pm': '11 AM - 1 PM',
  '1pm-3pm': '1 PM - 3 PM',
  '3pm-5pm': '3 PM - 5 PM'
}

export const GeorgiaStateBounds = {
  north: 35.0003,
  south: 30.3560,
  east: -81.0319,
  west: -85.6052
}

export const propertyTypes = [
  { label: 'Residence', value: 'Residence' },
  { label: 'Business', value: 'Business' }
]

export const validZipCodes = [
  '30011', '30620', '30622', '30517', '30548', '30656', '30666', '30680', '30101', '30102', '30103', '30104', '30120', '30121',
  '30137', '30139', '30145', '30161', '30171', '30178', '30183', '30184', '30110', '30116', '30117', '30118', '30179', '30180',
  '30182', '30185', '30101', '30102', '30004', '30107', '30114', '30115', '30028', '30040', '30534', '30143', '30075', '30183',
  '30184', '30188', '30189', '30337', '30349', '30354', '30288', '30294', '30215', '30297', '30228', '30236', '30238', '30250',
  '30260', '30273', '30274', '30296', '30281', '30101', '30102', '30339', '30106', '30168', '30157', '30141', '30144', '30152',
  '30126', '30008', '30060', '30062', '30064', '30066', '30067', '30068', '30127', '30075', '30080', '30082', '30188', '30220',
  '30230', '30259', '30263', '30265', '30268', '30275', '30276', '30277', '30289', '30534', '30143', '30148', '30306', '30307',
  '30315', '30316', '30317', '30319', '30324', '30329', '30338', '30340', '30341', '30345', '30346', '30350', '30360', '30322',
  '30002', '30021', '30288', '30012', '30094', '30030', '30032', '30033', '30034', '30035', '30294', '30038', '30058', '30072',
  '30079', '30039', '30083', '30087', '30088', '30084', '30106', '30168', '30134', '30135', '30122', '30180', '30185', '30187',
  '30205', '30214', '30215', '30238', '30269', '30276', '30290', '30004', '30005', '30107', '30028', '30040', '30041', '30534',
  '30097', '30506', '30024', '30004', '30005', '30009', '30022', '30303', '30305', '30306', '30307', '30308', '30309', '30310',
  '30311', '30312', '30313', '30314', '30315', '30316', '30317', '30318', '30319', '30324', '30326', '30327', '30328', '30331',
  '30334', '30336', '30337', '30338', '30339', '30342', '30344', '30349', '30350', '30354', '30363', '30332', '30097', '30213',
  '30268', '30092', '30296', '30075', '30076', '30024', '30291', '30340', '30360', '30011', '30620', '30517', '30518', '30519',
  '30019', '30096', '30097', '30017', '30548', '30043', '30044', '30045', '30046', '30047', '30052', '30071', '30093', '30092',
  '30039', '30078', '30087', '30024', '30084', '30517', '30518', '30519', '30542', '30501', '30504', '30506', '30507', '30548',
  '30566', '30567', '30575', '30294', '30228', '30234', '30236', '30248', '30252', '30253', '30273', '30281', '30013', '30014',
  '30016', '30052', '30054', '30070', '30164', '30025', '30605', '30606', '30622', '30666', '30680', '30101', '30132', '30157',
  '30134', '30141', '30127', '30153', '30178', '30179', '30180', '30107', '30534', '30139', '30143', '30148', '30177', '30183',
  '30012', '30013', '30094', '30016', '30294', '30058', '30052', '30252', '30281', '30075', '30076', '30022', '30004', '30009',
  '30350', '30077'
]
