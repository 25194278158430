<template>
  <input
    v-model="streetAddress"
    autocomplete="off"
    :placeholder="placeholder"
    class="relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 form-input rounded-md placeholder-gray-400 dark:placeholder-gray-500 text-sm px-2.5 py-1.5 shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400"
    @input="handleInputChange"
  >
  <div v-if="suggestions.length > 0" class="absolute z-10 mt-1 w-full bg-white dark:bg-gray-800 shadow-lg rounded-md">
    <ul class="max-h-[180px] overflow-auto rounded-md py-1 text-base leading-6 shadow-xs focus:outline-none sm:text-sm sm:leading-5">
      <li
        v-for="(suggestion, index) in suggestions"
        :key="index"
        class="cursor-pointer select-none relative py-2 pl-3 pr-9 text-gray-900 dark:text-white hover:bg-primary-500 hover:text-white dark:hover:bg-primary-400"
        @click="selectSuggestion(suggestion.placePrediction.place_id)"
      >
        {{ suggestion.placePrediction.description }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { GeorgiaStateBounds } from '~/utils'

const props = defineProps({
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Object,
    default: () => ({
      address: undefined,
      streetAddress: undefined,
      city: undefined,
      state: undefined,
      zip: undefined
    })
  }
})

const emit = defineEmits(['update:address'])

const streetAddress = ref<string | undefined>(props.modelValue.streetAddress)
const city = ref<string | undefined>(props.modelValue.city)
const state = ref<string | undefined>(props.modelValue.state)
const zip = ref<string | undefined>(props.modelValue.zip)

const suggestions = ref<any[]>([])
let sessionToken: any = null
let service: any = null
let placeServiceElement: any = null
let placeService: any = null

onMounted(() => {
  service = new google.maps.places.AutocompleteService()
  placeServiceElement = document.createElement('div')
  placeService = new google.maps.places.PlacesService(placeServiceElement)
})

watch([streetAddress, city, state, zip], () => {
  emit('update:address', {
    streetAddress: streetAddress.value,
    city: city.value,
    state: state.value,
    zip: zip.value
  })
})

const handleInputChange = () => {
  if (!streetAddress.value || streetAddress.value.length < 3) {
    suggestions.value = []
    return
  }
  if (!sessionToken) {
    sessionToken = new google.maps.places.AutocompleteSessionToken()
  }
  debouncedRequest()
}

const debouncedRequest = useDebounceFn(() => {
  const request = {
    input: streetAddress.value,
    sessionToken,
    locationRestriction: GeorgiaStateBounds,
    region: 'us',
    strictBounds: true
  }
  service.getPlacePredictions(request, (predictions: [], status: string) => {
    if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
      suggestions.value = []
      return
    }
    suggestions.value = predictions.map(prediction => ({ placePrediction: prediction }))
  })
}, 300)

const selectSuggestion = (placeId: string) => {
  handlePlaceSelected(placeId)
  suggestions.value = []
}

const handlePlaceSelected = (placeId: string) => {
  placeService.getDetails({
    placeId,
    fields: ['address_components']
  }, (result: object, status: string) => {
    if (status === google.maps.places.PlacesServiceStatus.OK && result) {
      let streetNumber = ''
      let route = ''

      city.value = ''
      state.value = ''
      zip.value = ''

      for (const component of result.address_components) {
        const addressType = component.types[0]
        switch (addressType) {
          case 'street_number':
            streetNumber = component.long_name
            break
          case 'route':
            route = component.long_name
            break
          case 'locality':
            city.value = component.long_name
            break
          case 'administrative_area_level_1':
            state.value = component.short_name
            break
          case 'postal_code':
            zip.value = component.long_name
            break
        }
      }
      streetAddress.value = `${streetNumber} ${route}`.trim()
    }
  })
  sessionToken = null
}
</script>
